import React from 'react';
import { Widget } from "@uploadcare/react-widget";
export default class ContactSection extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        spaModel:'',
        name: '',
        email: '',
        phone: '',
        message: '',
        availability: '',
        consent: false,
        images: '',
        errors: {
          spaModel: false,
          name: false,
          email: false,
          phone: false,
          message: false,
          availability: false,
          consent: false,
        },
        formInvalid: false
      };
    }

    handleChange = ({ target }) => {
      const { name, value } = target;
      this.setState({ [name]:  value});
    }

    toggleConsentCheckbox = ({ target }) => {
      const { name } = target;
      this.setState((s) => ({ ...s, [name]: !s[name]}));
   }
  
    setImage = (imagesUrl) => {
      this.setState({ images: imagesUrl })
    }
    
    handleSubmit = (e) => {
      e.preventDefault();
      const isValid = this.handleValidation();
      if (!isValid) {
        this.setState({ formInvalid: true });
        return;
      }

      const { email, spaModel, name, phone, message, availability, images } = this.state;

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({ "form-name": "contactForm", email, spaModel, name, phone, message, availability, imageLink: images })
      })
        .then(() => alert("Success!"))
        .catch(error => alert(error));
      this.clearErrors();
    }

  checkIsEmpty = (field = '') => {
    return (field === '');
  }

  clearErrors = () => {
    const resetErrorsFields = { ...this.state.errors };
    for( const field in resetErrorsFields) {
      resetErrorsFields[field] = false;
    }
    this.setState({ errors:  resetErrorsFields });
  }

  checkHasLessThanNCharacters = (field = '', n) => {
    return (field.length < n);
  }

  encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }
    
  handleValidation = () => {
    this.setState({ formInvalid: false });
    let isFormValid = true;
    const { email, spaModel, name, phone, message, availability, consent, isSubmitButtonEnabled } = this.state;
    const mandatoryFieldsWithNotLessThanThreeCharacters = { spaModel, name, phone, message, availability };
    for (const field in mandatoryFieldsWithNotLessThanThreeCharacters) {
      const currentField = mandatoryFieldsWithNotLessThanThreeCharacters[field];
      if (this.checkIsEmpty(currentField) || this.checkHasLessThanNCharacters(currentField, 3)) {
        isFormValid = false;
        const cloneErrorObj = { ...this.state.errors };
        cloneErrorObj[field] = true 
        this.setState({ errors: { ...cloneErrorObj } });
        break;
      } else if (field === 'consent' && !currentField) {
        isFormValid = false;
        const cloneErrorObj = { ...this.state.errors };
        cloneErrorObj[field] = true 
        this.setState({ errors: { ...cloneErrorObj } });
        break;
      } else {
        isFormValid = true;
        const cloneErrorObj = { ...this.state.errors };
        cloneErrorObj[field] = false 
        this.setState({ errors: { ...cloneErrorObj } })
      }
    }

    return isFormValid;
  }

  render() {
        const { email, spaModel, name, phone, message, availability, consent, formInvalid, errors } = this.state;

        return (
            <section className="section">
              <div className="container container--lg ">
                {formInvalid && (<p className="error">Please make sure all the fields are filled correctly.</p>)}
                <form className="container" name="contactForm" id="contactForm" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={this.handleSubmit}>
                <input type="hidden" name="form-name" value="contactForm" />
                <div className="contact-custom">
                  <div>
                  <div className="sr-only">
                    <label id="honeypot-label" htmlFor="honeypot">Don't fill this out if you're human:</label>
                    <input aria-labelledby="honeypot-label" id="honeypot" name="bot-field" />
                  </div>
                  <input aria-labelledby="honeypot-label" type="hidden" name="form-name" value="contactForm" />
                  <div className="form-group">
                    <label className={errors['spaModel'] ? 'error' : ''} id="spaModel-label" htmlFor="spaModel">Spa model:</label>
                    <input aria-labelledby="spaModel-label" type="text" name="spaModel" id="spaModel" value={spaModel} onChange={this.handleChange} placeholder="Enter spa model" required />
                  </div>
                  <div className="form-group">
                    <label className={errors['name'] ? 'error' : ''} id="name-label" htmlFor="name">Your first and last name:</label>
                    <input  aria-labelledby="name-label" type="text" name="name" id="name" value={name} onChange={this.handleChange} placeholder="Enter your name" required />
                  </div>
                  <div className="form-group">
                    <label className={errors['email'] ? 'error' : ''} id="email-label" htmlFor="email">Your email:</label>
                    <input  aria-labelledby="email-label" type="email" name="email" id="email" value={email} onChange={this.handleChange} placeholder="Enter your email" required />
                  </div>
                  <div className="form-group">
                    <label className={errors['phone'] ? 'error' : ''} id="phone-label" htmlFor="phone">Your phone number:</label>
                    <input  aria-labelledby="phone-label" type="text" name="phone" id="phone" value={phone} onChange={this.handleChange} placeholder="Enter your phone number" required />
                  </div>
                  <div className="form-group">
                    <label className={errors['message'] ? 'error' : ''} id="message-label" htmlFor="message">The issues you’ve experienced with your spa:</label>
                    <textarea aria-labelledby="message-label" name="message" id="message" value={message} onChange={this.handleChange} rows="5" placeholder="Your message" />
                  </div>
                  <div className="form-group">
                    <label className={errors['availability'] ? 'error' : ''} id="availability-label" htmlFor="availability">What’s your general availability for a call in the next 1-3 days?:</label>
                    <input  aria-labelledby="availability-label" type="text" name="availability" id="availability" value={availability} onChange={this.handleChange} placeholder="Enter availability" required />
                  </div>
                  <div className="form-group form-checkbox">
                    <input  aria-labelledby="consent-label" type="checkbox" name="consent" checked={consent} onChange={this.toggleConsentCheckbox} id="consent" />
                    <label className={errors['consent'] ? 'error' : ''} id="consent-label" htmlFor="consent">I understand that this form is storing my submitted information so I can be
                      contacted.</label>
                </div>
                </div>
                <div>
                  <label htmlFor='file'>Your file:</label>
                    <Widget
                      publicKey='8dd7866d2e699fd79a77'
                      id='file'
                      onChange={(fileInfo) => this.setImage(fileInfo.cdnUrl)}
                      name='name'
                      tabs='file url'
                      previewStep='true'
                      multipleMax= '3'
                      multiple
                    />
                    <input type="hidden" name="imageLink" value={this.state.images} />
                </div>
                </div>
                  <div className="form-submit">
                  <button type="submit" className={`button`} disabled={!this.state.consent}>Send</button>
                  </div>
                </form>
              </div>
            </section>
        );
    }
}
