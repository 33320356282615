import React from 'react';
import _ from 'lodash';

import {classNames} from '../utils';
import SectionActions from './SectionActions';

export default class ImagesSection extends React.Component {
    render() {
        let section = _.get(this.props, 'section', null);
        let images  = _.get(section,'images',null);

        return (
            <section className="section section--cta">
              <div className="container container--lg">
                <div className={classNames('section__body', '', {'inverse bg-blue': _.get(section, 'has_background', null) && (_.get(section, 'background_color', null) === 'blue'), 'bg-gray': _.get(section, 'has_background', null) && (_.get(section, 'background_color', null) === 'gray')})}>
                <div className="container container--md images-section">
                    {
                        images.map((image) => {
                            return (
                                <img className="" src={image} alt="" width="250" />
                            )
                        })
                    }
                </div>
                </div>
              </div>
            </section>
        );
    }
}
